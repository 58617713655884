import 'react-native-gesture-handler';
import {Button, Pressable, View, StyleSheet, style, Text} from 'react-native';
import React, { useState } from "react";
import Values from'./Values.json';

const DetailsScreen = ({navigation,route}) => {
  const id = route.params.id;
  const id2 = route.params.id2;
  const array = route.params.array;
  const socNum = route.params.socNum;
  const isEnabled = route.params.isEnabled;
  const [count, setCount] = useState(0);
  const OnPress = () => {

    if(Values.Details[id2 - 1][1] == 2){
      if (count >= 1 ){
        if(id >= 20){
          navigation.navigate('Finish',{id:id,isEnabled:isEnabled,socNum:socNum,array:array});
        }
        else if(id == 5 || id == 15 || id == 16|| id == 17){
          navigation.navigate("DecisionSpecial",{id:id,array:array,socNum:socNum,isEnabled:isEnabled});
        }else{
          navigation.navigate("Decision",{id:id,array:array,socNum:socNum,isEnabled:isEnabled});
        }
      }
      if(count < 1){
        setCount(count + 1);
      }
    }
    else{
      if(id == 15){
      navigation.navigate("DecisionSpecial",{id:id,array:array,socNum:socNum,isEnabled:isEnabled});
    }else{
      navigation.navigate("Decision",{id:id,array:array,socNum:socNum,isEnabled:isEnabled});
    }
    }
  }
  return(
    <Pressable style={[styles.container,{backgroundColor: isEnabled ? '#383838' : 'white'}]} onPress={OnPress}>
    {Values.Details[id2 - 1][1] == 2 ? 
    <View style={styles.container}>
      <View style={[ count == 0 ? styles.textBoxOpen : styles.textBoxClosed,{borderColor: isEnabled ? 'white' : '#383838'}]}>
        <Text style={[{ color: count == 0 ? (isEnabled ? 'white' : 'black') : 'transparent'}, styles.text ]}>{Values.Details[id2 - 1][2]}</Text>
      </View>
      <View style={[ count == 0 ? styles.textBox2Closed : styles.textBox2Open,{borderColor: isEnabled ? 'white' : '#383838'}]}>
        <Text style={[{ color: count == 1 ? (isEnabled ? 'white' : 'black') : 'transparent' }, styles.text ]}>{Values.Details[id2 - 1][3]}</Text>
      </View>
    </View> : 
    <View style={[styles.textBoxOpen,{borderColor: isEnabled ? 'white' : '#383838'}]}>
        <Text style={[styles.text,{color: isEnabled ? 'white' : 'black'}]}>{Values.Details[id2 - 1][2]}</Text>
    </View>
  }
    </Pressable>
  )
};
const styles = StyleSheet.create({
  container:
  {
    flex:1,
    justifyContent:'space-evenly',
    alignItems:'center',
  },
  textBoxOpen:
  {
    height:350,
    width:350,
    borderRadius:175,
    borderWidth:3,
    justifyContent:'center',
    alignItems:'center',
    padding:10,
  },
  textBoxClosed:
  {
    height:100,
    width:100,
    borderRadius:50,
    borderWidth:3,
  },
  textBox2Open:
  {
    height:350,
    width:350,
    borderRadius:175,
    borderWidth:3,
    justifyContent:'center',
    alignItems:'center',
    padding:10,
  },textBox2Closed:
  {
    height:100,
    width:100,
    borderRadius:50,
    borderWidth:3,
  },
  text:
  {
    fontSize:16,
    textAlign:'center'
  },

});

export default DetailsScreen;
