// React Native Rotate Image View Using Animation
// https://aboutreact.com/react-native-rotate-image-view-using-animation/

// import React in our code
import React, { useState } from "react";
// import all the components we are going to use
import {
  SafeAreaView,
  StyleSheet,
  View,
  Animated,
  Easing,
  TouchableHighlight,
  Text,
  TouchableOpacity,
  Pressable,
} from 'react-native';

const SpinningLogo = () => {
  let rotateValueHolder = new Animated.Value(0);
  const startImageRotateFunction = (startNum, endNum) => {
    rotateValueHolder.setValue(startNum);
    Animated.timing(rotateValueHolder, {
      toValue: endNum,
      duration: 10000 * Math.random() * Math.abs(startNum - endNum) / 5,
      easing: Easing.inOut(Easing.quad),
      useNativeDriver: false,
    }).start();
  };

  const RotateData = rotateValueHolder.interpolate({
    inputRange: [0, 10],
    outputRange: ['0deg', '3600deg'],
  });
  var counter = 0;
  var pastCounter = 0;
  const OnPress = () => {
    counter = Math.floor(Math.random() * 9 + 1);
    startImageRotateFunction(pastCounter,counter);
    pastCounter = counter;
    
};

  return (
        <Pressable
          onPress={OnPress} style={{backgroundColor:'transperent'}}>
            <Animated.Image
              style={{
                width: 80,
                height: 80,
                borderRadius:60,
                transform: [{ rotate: RotateData }],
              }}
              source={require('./Picures/KL_logo.png')}
            
            />
        </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
  },
  titleText: {
    fontSize: 22,
    textAlign: 'center',
    fontWeight: 'bold',
    padding: 20,
  },
  buttonStyle: {
    fontSize: 16,
    color: 'white',
    backgroundColor: 'green',
    padding: 5,
    marginTop: 32,
    minWidth: 250,
  },
  buttonTextStyle: {
    padding: 5,
    color: 'white',
    textAlign: 'center',
  },
});

export default SpinningLogo;