import 'react-native-gesture-handler';
import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, Animated, Image, Pressable, TouchableOpacityBase } from "react-native";
import SpinningLogo from './SpinningLogo';
import Values from './Values.json'

const DecisionScreen = ({navigation, route}) => {
  const id = route.params.id;
  const array = route.params.array;
  const socNum = route.params.socNum;
  const [decNum, setDecNum] = useState(0);
  const [futureId, setFutureId] = useState(0);
  const name1 = route.params.name;
  const title = Values.Decisions[id][5];
  const description = Values.Decisions[id][6];
  const decision1 = Values.Decisions[id][7];
  const decision2 = Values.Decisions[id][8];
  const isEnabled = route.params.isEnabled;
  
  const blackArraw = require('../assets/arrow1.png');
  const whiteArrow = require('../assets/arrow1_white.png');

  const ChoiceButton = (props) => {
    return(
    <TouchableOpacity
      style={{
        borderColor:'rgba(0,0,0,0.8)',
        borderWidth:0.5,
        alignItems:'center',
        justifyContent:'center',
        alignContent:'center',
        width:300,
        height:"25%",
        elevation:5,
        borderRadius:100,
        backgroundColor: isEnabled ? 'white' : '#383838',//props.BacCol,
      }}
      onPress={props.method}
    >
      <Text style={[styles.textContainer,{color: isEnabled ? 'black' : 'white'}]}>{props.text}</Text>
    </TouchableOpacity>
    );
  };
  const OnButtonPress1 = () => {
   OnButtonPressMain(0);
  };
  const OnButtonPress2 = () => {
   OnButtonPressMain(1);
  };
  const OnButtonPressMain = (decNum) => {
    var LocalId = Values.Decisions[id][1][decNum];
    if((id == 15 || id == 19) && socNum >= 30 && decNum == 1)
    {
      navigation.navigate('Finish',{id:LocalId,isEnabled:isEnabled,socNum:socNum,array:array});
    }else{
      if((id == 15 || id == 19) && socNum < 30 && decNum == 1){
        LocalId = 18;
      }
      if(Values.Decisions[id][2][decNum] != 0){
        navigation.navigate("Details",{id:LocalId,id2:Values.Decisions[id][2][decNum],array:array,socNum:socNum,isEnabled:isEnabled});
    }
    else if(LocalId >= 20){
      navigation.navigate('Finish',{id:LocalId,isEnabled:isEnabled,socNum:socNum,array:array});
    }
    else{
        if(LocalId == 5 || LocalId == 15 || LocalId == 16|| LocalId == 17 || LocalId == 11 ||LocalId == 19){
          navigation.navigate("DecisionSpecial",{id:LocalId,array:array,socNum:socNum,isEnabled:isEnabled});
        }
        else{
          navigation.navigate("Decision",{id:LocalId,array:array,socNum:socNum,isEnabled:isEnabled});
        }
      }
    }
    
  };
  const OnPressArrow = () => {
    navigation.navigate('Side Menu',{ScreenID:2,socNum:socNum,array:array,isEnabled:isEnabled});
  }
    return(
      <View style={{flex: 1, flexDirection: 'column',justifyContent:'center',alignItems:'center',backgroundColor: isEnabled ? '#383838' : 'white',}}>
      <View style={{flex:1.5,backgroundColor: 'transparent',justifyContent:'flex-end',}}>
          <SpinningLogo/>
      </View>
      <View style={{flex:0.5, backgroundColor: 'transparent',justifyContent:'flex-end',alignItems:'center'}}>
        <Text style={{fontSize:18,textAlign:'center',color: isEnabled ? 'white' : 'black'}}>{title}</Text>
      </View>
      <View style={{flex:3, backgroundColor: 'transparent',justifyContent:'center',alignItems:'center'}} >
        <View style={[styles.rectangleContainer,{backgroundColor: isEnabled ? 'white' : '#383838' }]}>
          <Text style={[styles.textContainer,{color: isEnabled ? 'black' : 'white'}]}>{description}</Text>
        </View>
      </View>
      <View style={{flex:4, backgroundColor: 'transparent',justifyContent:'space-evenly',alignItems:'center'}}>
      <ChoiceButton number='0' BacCol='rgba(170,210,55,2)' text={decision1} method={OnButtonPress1}/>
      <ChoiceButton number='1' BacCol='rgba(120,200,150,1)'text={decision2} method={OnButtonPress2}/>
      </View>
      <View style={{flex:1, backgroundColor: 'transparent',alignItems:'center',alignContent:'center',justifyContent:'center',paddingTop:10}}>
          <Pressable style={{backgroundColor:'transparent'}}onPress={OnPressArrow}>
            <Image source={isEnabled ? whiteArrow : blackArraw} style={{height:50,width:50}}></Image>
          </Pressable>
          <View style={{flex:1,backgroundColor:'transparent'}}>
            <Text style={[{color: isEnabled ? 'white' : 'black'},{textAlign:'center',fontSize:13}]}>Dosežene kompetence</Text>
          </View>
        </View>
    </View>
    );
  };
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-evenly",
    flexDirection:'column',
    alignItems:'center',
  },
  rectangleContainer:{
    //borderWidth:1,
    width:"80%",
    height:"94%",
    borderRadius:25,
    padding:10,
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:'rgba(85,100,160,1)',
  },
  circleContainer:{
    width:100,
    height:100,
    borderRadius:50,
    backgroundColor:'black',
    borderWidth:1,
    borderColor:'white',
  },
  circleLineContainer:{
    width: 2,
    color:'cyan',
    backgroundColor:'cyan',
    flex:1,
  },
  button: {
    //borderWidth: 1.5,
    borderColor:'rgba(0,0,0,0.8)',
    alignItems:'center',
    justifyContent:'center',
    alignContent:'center',
    width:300,
    height:65,
    elevation:5,
    borderRadius:100,
    padding:5,
  },
  countContainer: {
    alignItems: "center",
    padding: 10
  },
  textContainer:{
    fontSize:15,
    textAlign:'center',
  }
});

export default DecisionScreen;