import 'react-native-gesture-handler';
import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, Animated, Pressable, Image } from "react-native";
import Values from './Values.json';

const FinishScreen = ({navigation, route}) => {
    const id = route.params.id;
    const title = Values.Decisions[20][1];
    const array = route.params.array;
    const socNum = route.params.socNum;
    const description = Values.Decisions[id][2];
    const isEnabled = route.params.isEnabled;
    const OnPressEvent = () => {
        navigation.navigate('Home',{id:id,isEnabled:isEnabled});
    };
    const OnPressEvent2 = () => {
        navigation.navigate('Side Menu',{ScreenId:id,isEnabled:isEnabled,socNum:socNum,array:array});
        console.log(id);
        console.log(array);
    };
    return(
    <View style={[styles.container,{backgroundColor: isEnabled ? '#383838' : 'white'}]}>
        <View style={{flex:1,justifyContent:'center',alignContent:'center',backgroundColor:'transparent'}}>
        <Text style={[styles.textStyle,{color : isEnabled ? 'white' : 'black'}]}>{title}</Text>
        </View>
        <View style={{flex:2,backgroundColor:'transparent'}}>
            <View style={{flex:5,justifyContent:'center',alignContent:'center',backgroundColor:'transparent',paddingHorizontal:10,paddingBottom:10}}>
                <Text style={{fontSize:16,textAlign:'center',alignItems:'center',color : isEnabled ? 'white' : 'black'}}>{description}</Text>
            </View>
            <View style={{flex:8,justifyContent:'space-evenly',alignContent:'center',backgroundColor:'transparent'}}>
                <Pressable style={{width:300,height:75,elevation:5,borderRadius:100,alignSelf:'center',justifyContent:'center',backgroundColor: isEnabled ? 'white' : '#383838',borderWidth:3}} onPress={OnPressEvent}>
                    <Text style={{fontSize:20,textAlign:'center',alignItems:'center',color : isEnabled ? 'black' : 'white'}}>ZAČETNI ZASLON</Text>
                </Pressable>
                <Pressable style={{width:300,height:75,elevation:5,borderRadius:100,alignSelf:'center',justifyContent:'center',backgroundColor: isEnabled ? 'white' : '#383838',borderWidth:3}} onPress={OnPressEvent2}>
                    <Text style={{fontSize:20,textAlign:'center',alignItems:'center',color : isEnabled ? 'black' : 'white'}}>PREGLED KOMPETENC</Text>
                </Pressable>
            </View>
            <View style={{flex:2}}/>
        </View>
    </View>
    );
  };
const styles = StyleSheet.create({
  container: {
      flex:1,
  },
  textStyle: {
      fontSize: 30,
      textAlign:'center',
      alignItems:'center',
  },
});

export default FinishScreen;