import 'react-native-gesture-handler';
import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, Animated, Pressable, Image } from "react-native";
import Values from './Values.json';

const CreditScreen = ({navigation,route}) => {
  const id = route.params.id;
  const isEnabled = route.params.isEnabled;
  const OnPress = () => {
    navigation.navigate('Finish',{id:id,isEnabled:isEnabled});
  }
    return(
    <View style={styles.container}>
        <View style={{flex:8,alignContent:'center',justifyContent:'space-evenly',paddingTop:50}}>
        <Image source={require('../Views/Picures/Logo1.png')} style={{width:210,height:90,alignSelf:'center'}}></Image>
        <Image source={require('../Views/Picures/Nefiks.png')} style={{width:299,height:95,alignSelf:'center'}}></Image>
        <Image source={require('../Views/Picures/Mol.png')} style={{width:300,height:155,alignSelf:'center'}}></Image>
        </View>
        <View style={{flex:2,alignItems:'center',justifyContent:'center'}}>
          <Pressable style={{width:300,height:75,borderRadius:35,borderWidth:2,elevation:2,justifyContent:'center',backgroundColor:'#383838'}} onPress={OnPress}>
            <Text style={{fontSize:18,alignSelf:'center',color:'white'}}>Nazaj</Text>
          </Pressable>
        </View>
    </View>
    );
  };
const styles = StyleSheet.create({
  container: {
      flex:1,
      backgroundColor:'white',
  },
  textStyle: {
      fontSize: 30,
      textAlign:'center',
      alignItems:'center',
  },
});

export default CreditScreen;