import 'react-native-gesture-handler';
import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, Image, Alert } from "react-native";
import SpinningLogo from './SpinningLogo';
import values from './Values.json';

const UniDecisionScreen = ({navigation, route}) => {
  const [count, setCount] = useState(0);
  const NameNum = route.params.nameNum;
  const isEnabled = route.params.isEnabled;
  const UniButton = (props) => {
    return(
    <TouchableOpacity
      style={{
        flexDirection:'row',
        borderColor:'rgba(0,0,0,0.8)',
        borderWidth:0.5,
        borderRadius:35,
        width:300,
        height:70,
        alignItems:'center',
        justifyContent:'center',
        backgroundColor: isEnabled ? 'white' : '#383838',
      }}
      onPress={props.OnPress}
    >
    <Text style={[styles.textContainer,{color: isEnabled ? 'black' : 'white'}]}>{values.Collage[NameNum][props.Num]}</Text>
    </TouchableOpacity>
    );
  };
  const AlertTime = () =>
    Alert.alert(
      "Opala!",
      "Niste ste sprejeti na fakulteto, ker ne izpolnjujete pogojev za vpis!",
      [
        { text: "Poskusi ponovno"}
      ]
    );
    const AlertTimeGood = () =>
    Alert.alert(
      "Bravo",
      "Bili ste sprejeti na željeno fakulteto!",
      [
        { text: "Naprej"}
      ]
    );
  const OnPress3 = () => {
    AlertTimeGood();
    navigation.navigate('Decision', {name:2,id:0,array:[],socNum:0,isEnabled:isEnabled});
  };
    return(
    <View style={{flex: 1,backgroundColor: isEnabled ? '#383838' : 'white'}}>
        <View style={{flex: 10, backgroundColor:'transparent', justifyContent:'center',paddingBottom:10}}>
            <View style={{flex:2.5,justifyContent:'flex-end',alignItems:'center'}}>
            <SpinningLogo/>
            </View>
            <View style={{flex:3.5,paddingTop:10}}>
              <View style={{flex:5,flexDirection:'row',alignContent:'space-between',alignItems:'center',justifyContent:'space-evenly',paddingTop:10}}>
              <Text style={{fontSize:18,textAlign:'center',color: isEnabled ? 'white' : 'black'}}>Točke na maturi:{"\n"}{values.Achivments[NameNum][0]}</Text>
              <Text style={{fontSize:18,textAlign:'center',color: isEnabled ? 'white' : 'black'}}>Uspeh:{"\n"}{values.Achivments[NameNum][1]}</Text>
              </View>
              <View style={{flex:1,alignItems:'center',justifyContent:'flex-end',paddingTop:10}}>
                <Text style={{fontSize:20,color: isEnabled ? 'white' : 'black'}}>Izberi fakulteto:</Text>
              </View>
            </View>
          </View> 
        <View style={{flex: 10, alignItems:'center', justifyContent:'space-evenly',backgroundColor:'transparent'}}>
          <UniButton name={'Marko'} Num={0} picturePath = {require('./Picures/Marko.png')} srednjaSola={values.HighSchools[0]} OnPress={AlertTime}></UniButton>
          <UniButton name={'Pia'} Num={1} picturePath = {require('./Picures/Pia.png')} srednjaSola={values.HighSchools[1]} OnPress={AlertTime}></UniButton>
          <UniButton name={'Valentin'} Num={2} picturePath = {require('./Picures/Valentin.png')} srednjaSola={values.HighSchools[2]} OnPress={OnPress3}></UniButton>
        </View>
        <View style={{flex: 4, backgroundColor:'transparent'}} />
    </View>
    );
  };
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-evenly",
    flexDirection:'column',
    alignItems:'center',
  },
  rectangleContainer:{
    //borderWidth:1,
    width:300,
    height:200,
    borderRadius:25,
    padding:10,
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:'rgba(85,100,160,1)',
  },
  circleContainer:{
    width:100,
    height:100,
    borderRadius:50,
    backgroundColor:'black',
    borderWidth:1,
    borderColor:'white',
  },
  circleLineContainer:{
    width: 2,
    color:'cyan',
    backgroundColor:'cyan',
    flex:1,
  },
  button: {
    borderColor:'rgba(0,0,0,0.8)',
    alignItems:'center',
    justifyContent:'center',
    alignContent:'center',
    width:300,
    height:65,
    elevation:5,
    borderRadius:100,
    padding:5,
  },
  countContainer: {
    alignItems: "center",
    padding: 10
  },
  textContainer:{
    fontSize:17,
    textAlign:'center',
  }
});

export default UniDecisionScreen;