import 'react-native-gesture-handler';
import { StyleSheet, Text, TouchableOpacity, View, Animated, Pressable,ScrollView, Linking } from "react-native";
import React, { useCallback } from "react";
import SpinningLogo from './SpinningLogo';
import { back } from 'react-native/Libraries/Animated/src/Easing';

const PrologScreen = ({navigation,route}) => {
  const isEnabled = route.params.isEnabled;
  const url = 'https://nefiks.si/article/id/76';
  const OnPress = () => {
    navigation.navigate('PersonDecision',{isEnabled:isEnabled});
    //setCount(prevCount => prevCount + 1)
  }
  const handlePress = useCallback(async () => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url);
    } else {
      Alert.alert(`Error 404: ${url}`);
    }
  }, [url]);
    return(
    <View style={[styles.container,{backgroundColor: isEnabled ? '#383838' : 'white'}]}>
        <View style={{flex:2.5}}>
          <View style={{flex:1.5,backgroundColor:'transparent',justifyContent:'flex-end',alignItems:'center',marginBottom:20}}>
            <SpinningLogo/>
          </View>
          <View style={{flex:5,paddingHorizontal:10}}>
          <ScrollView style={[{backgroundColor:'transparent',paddingHorizontal:10,borderWidth:1},{borderColor: isEnabled ? 'white' : 'black'}]}>
          <Text style={[styles.textStyle,{color: isEnabled ? 'white' : 'black'}]}>Bodi dobrodelen in podari 1% svoje dohodnine Zavodu Nefiks in s tem pomagaj pri nadaljnjem razvoju aplikacije.{'\n\n'}Ali veš, da:</Text>
          <Text style={[styles.textStyle2,{color: isEnabled ? 'white' : 'black'}]}>●	Vsako leto država 1 % tvoje dohodnine razporedi po proračunu po lastni presoji.{'\n'}●	Imaš možnost vplivati na to, kam gre tvoja dohodnina.{'\n'}●	Vsako leto 60 % državljanov svoje dohodnine ne nameni nikomur.{'\n'}●	Na leto prikrajšamo nevladne organizacije za 5 milijonov evrov.</Text>
          <Text style={[styles.textStyle,{color: isEnabled ? 'white' : 'black'}]}>Zakaj donirati ravno nam?</Text>
          <Text style={[styles.textStyle2,{color: isEnabled ? 'white' : 'black'}]}>●	Ker bomo s pridobljenimi sredstvi lahko razvijali to in podobne aplikacije.{'\n'}●	Ker izvajamo projekte za mlade iskalce zaposlitve in jim tako pomagamo hitreje do zaposlitve.{'\n'}●	Mladim nudimo karierno svetovanje.{'\n'}●	Mlade osveščamo o njihovih kariernih možnostih in jih spodbujamo pri raziskovanju.{'\n'}●	Podpiramo nevladne organizacije pri doseganju njihovih ciljev.</Text>
          </ScrollView>
          </View>
          
        </View>
        <View style={styles.PressableViewStyle}>
            <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
              <Pressable style={[styles.PressableStyle,{borderColor: isEnabled ? 'white' : 'black'}]} onPress={handlePress}>
                    <Text style={[{fontSize:17},{color: isEnabled ? 'white' : 'black'}]}>PODPRI NAŠE DELO</Text>
                </Pressable>
                
            </View>
        </View>
    </View>
    );
  };
const styles = StyleSheet.create({
  container: {
      flex:1,
      justifyContent:'center',
      
  },
  textStyle: {
      fontSize: 20,
      textAlign:'center',
      alignItems:'center',
      paddingVertical:10
  },
  textStyle2: {
    fontSize: 16,
    textAlign:'left',
    alignItems:'center',
    paddingVertical:10
},
  PressableViewStyle:{
    flex:0.5,
  },
  PressableStyle:{
      width:200,
      height:70,
      borderRadius:35,
      borderWidth:2,
      justifyContent:'center',
      alignItems: 'center',
  },
});

export default PrologScreen;