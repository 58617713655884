import 'react-native-gesture-handler';
import React, { useState } from "react";
import { StyleSheet, Text, Image, View, Animated, Pressable,ScrollView } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import SpinningLogo from './SpinningLogo';

const PrologScreen = ({navigation,route}) => {
  const isEnabled = route.params.isEnabled;
  const OnPress = () => {
    navigation.navigate('Home',{isEnabled:isEnabled});
    //setCount(prevCount => prevCount + 1)
  }
    return(
        <View style={[styles.container,{backgroundColor: isEnabled ? '#383838' : 'white'}]}>
        <View style={{flex:2.5}}>
          <View style={{flex:1.5,backgroundColor:'transparent',justifyContent:'flex-end',alignItems:'center',marginBottom:20}}>
            <SpinningLogo/>
          </View>
          <View style={{flex:5,paddingHorizontal:10}}>
          <ScrollView style={[{backgroundColor:'transparent',paddingHorizontal:10,borderWidth:1},{borderColor: isEnabled ? 'white' : 'black'}]}>
          <Text style={[styles.textStyle,{color: isEnabled ? 'white' : 'black'}]}>Karierni labirint je aktivnost, v kateri mladi razmišljajo o svoji karierni poti, na podlagi odločitev pa najdejo pot iz labirinta študentskega življenja, ki se zaključi s prvo zaposlitvijo</Text>
          <Text style={[styles.textStyle2,{color: isEnabled ? 'white' : 'black'}]}>Namen aplikacije je mlade opolnomočiti na njihovi karierni poti ter jih preko interaktivne dinamike opozoriti na celostno pridobivanje ključnih informacij, ki jih potrebujejo za oblikovanje svoje karierne poti.</Text>
          <Text style={[styles.textStyle,{color: isEnabled ? 'white' : 'black'}]}>Aplikacija je nastala pod okriljem Zavoda Nefiks, ki je nastal kot ideja Društva mladinski ceh in Zavoda za mlade, z željo izdelati orodje za beleženje (in posledično priznavanje) neformalno pridobljenega znanja. Ob učenju in pridobivanju novih dragocenih izkušenj, ki so kasneje fleksibilno prenosljive v življenje ljudi, je Zavod Nefiks izdelal sistem beleženja neformalno pridobljenega znanja na enem mestu (e-Nefiks) s katerim lahko mladi svoje kompetence in znanje primerno zabeležijo, organizacije pa jih lahko preprosto potrdijo. Z izdelano aplikacijo se uresničuje poslanstvo Zavoda Nefiks, ki pomaga mladim pri kariernem razvoju in zaposlovanju.</Text>
          <Text style={[styles.textStyle2,{color: isEnabled ? 'white' : 'black'}]}>Avtorji:{'\n'}Alenka Blazinšek Domenis{'\n'}Nives Felić{'\n'}Silvija Gregorčič{'\n'}Sara Pavlović{'\n'}Katarina Vetrih{'\n'}{'\n'}Razvijalec aplikacije:{'\n'}Aljaž Vetrih{'\n'}{'\n'}Aplikacija je rezultat projekta Karierni labirint, ki ga sofinancirata Urad za mladino – MOL in Urad RS za mladino.</Text>
          <View style={{height:500,justifyContent:'space-evenly'}}>
            <Image source={require('../Views/Picures/Logo1.png')} style={{width:210,height:90,alignSelf:'center'}}></Image>
            <Image source={require('../Views/Picures/Nefiks.png')} style={{width:299,height:95,alignSelf:'center'}}></Image>
            <Image source={require('../Views/Picures/Mol.png')} style={{width:300,height:155,alignSelf:'center'}}></Image>
          </View>
          
          </ScrollView>
          </View>
          
        </View>
        <View style={[styles.PressableViewStyle,{background: isEnabled ? '#383838' : 'white'}]}>
            <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
              <Pressable style={[styles.PressableStyle,{borderColor: isEnabled ? 'white' : 'black'}]} onPress={OnPress}>
                    <Text style={[{fontSize:17},{color: isEnabled ? 'white' : 'black'}]}>NAZAJ</Text>
                </Pressable>
                
            </View>
        </View>
    </View>
    );
  };
const styles = StyleSheet.create({
  container: {
      flex:1,
      justifyContent:'center',
      
  },
  textStyle: {
      fontSize: 18,
      textAlign:'center',
      alignItems:'center',
      paddingVertical:10
  },
  textStyle2: {
    fontSize: 18,
    textAlign:'center',
    alignItems:'center',
    paddingVertical:10
},
  PressableViewStyle:{
    flex:0.5,
  },
  PressableStyle:{
      width:200,
      height:70,
      borderRadius:35,
      borderWidth:2,
      justifyContent:'center',
      alignItems: 'center'
  },
});

export default PrologScreen;