import 'react-native-gesture-handler';
import React, { useState } from "react";
import { StyleSheet, Text, Image, View, Animated, Pressable, Switch } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

const HomeScreen = ({navigation}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const OnPress = () => {
    navigation.navigate('Prolog',{isEnabled:isEnabled});
  }
  const OnPress2 = () => {
    navigation.navigate('Activities',{isEnabled:isEnabled});
  }
  const OnPress3 = () => {
    navigation.navigate('About',{isEnabled:isEnabled});
  }
  const BlackMoon = require('./Picures/moon_icon.png');
  const WhiteMoon = require('./Picures/moon_icon_white.png');
  const BlackSun = require('./Picures/sun_icon.png');
  const WhiteSun = require('./Picures/sun_icon_white.png');
  const ThemeSwtitch = (props) => {
    return(
    <View style={{flex:1,justifyContent:'center',alignItems:'flex-start',flexDirection:'row'}}>
              <Image source={isEnabled ? WhiteSun : BlackSun} style={{height:30,width:30}}></Image>
              <Switch 
              trackColor={{ false: "black", true: "white" }}
              thumbColor={isEnabled ? "black" : "white"}
              onValueChange={() => setIsEnabled(!isEnabled)}
              value={isEnabled}
              />
              <Image source={isEnabled ? WhiteMoon : BlackMoon} style={{height:28,width:28}}></Image>
            </View>
    )
  }
    return(
      <View
      style={[styles.container,{backgroundColor: isEnabled ? '#383838' : 'white'}]}
      >
        
        <View style={{flex:2,backgroundColor:'transparent',justifyContent:'center'}}>
              <View style={styles.ImageView}>
                  <Image style={{height:79,width:187}} source={require('./Picures/KL.png')}></Image>
              </View>
              <View style={{flex:1}}/>
              <View style={{flex:6,backgroundColor:'transparent',alignItems:'center'}}>
                <ThemeSwtitch/>
              </View>
        </View>
          <View style={{flex:2,backgroundColor:'transparent', justifyContent:'space-evenly'}}>
            <View style={styles.pressableView}>
                  <Pressable style={[styles.pressableView,{borderWidth:2,backgroundColor:'rgba(138,196,64,1)'}]} onPress={OnPress}>
                      <Text style={styles.text}>ZAČNI IGRO!</Text>
                  </Pressable>
            </View>
            <View style={styles.pressableView}>
                  <Pressable style={[styles.pressableView2,{borderWidth:2}]} onPress={OnPress2}>
                      <Text style={styles.text2}>PODPRI NAŠE AKTIVNOSTI</Text>
                  </Pressable>
            </View>
            <View style={styles.pressableView}>
                  <Pressable style={[styles.pressableView3,{borderWidth:2}]} onPress={OnPress3}>
                      <Text style={styles.text2}>O NAS</Text>
                  </Pressable>
            </View>
          </View>
      </View>
    );
  };
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-evenly",//rgba(40,40,40,1)
    flexDirection:'column',
    alignItems:'center',
  },
  ImageView:{
    flex:4,
    alignItems:'center',
    justifyContent:'flex-end',
  },
  pressableView: {
    height:75,
    width:300,
    alignItems:'center',
    justifyContent:'center',
    borderRadius:50,
  },
  pressableView2: {
    height:75,
    width:250,
    alignItems:'center',
    justifyContent:'center',
    borderRadius:50,
    backgroundColor:'rgba(138,196,64,1)',
  },
  pressableView3: {
    height:75,
    width:200,
    alignItems:'center',
    justifyContent:'center',
    borderRadius:50,
    backgroundColor:'rgba(138,196,64,1)',
  },
  text:{
      fontSize:16,
      color:'black',
  },
  text2:{
    fontSize: 14,
    color:'black',
  },
  textTitle:{
      fontSize:23,
  },
});

export default HomeScreen;