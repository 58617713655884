import 'react-native-gesture-handler';
import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, Animated, Pressable } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import SpinningLogo from './SpinningLogo';
import { isEnabled } from 'react-native/Libraries/Performance/Systrace';

const PrologScreen = ({navigation,route}) => {
  const isEnabled = route.params.isEnabled;
  const OnPress = () => {
    navigation.navigate('PersonDecision',{isEnabled:isEnabled});
    //setCount(prevCount => prevCount + 1)
  }
    return(
    <View style={[styles.container,{backgroundColor: isEnabled ? '#383838' : 'white'}]}>
        <View style={{flex:1.5}}>
          <View style={{flex:1.5,backgroundColor:'transparent',justifyContent:'center',alignItems:'center'}}>
            <SpinningLogo/>
          </View>
          <View style={{flex:1.95,backgroundColor:'transparent',justifyContent:'flex-end'}}>
          <Text style={[styles.textStyle,{color: isEnabled ? 'white' : 'black'}]}>Živjo! Pred tabo je igra Karierni labirint. V igri boš izbran lik peljal skozi labirint študentskega življenja do prve zaposlitve, pri tem pa boš nabiral_a različne kompetence ter širil_a svojo socialno mrežo. Vse odločitve, ki jih sprejmeš, bodo vplivale na karierno pot tvojega lika, zato o njih dobro razmisli! Srečno!</Text>
          </View>
            </View>
        <View style={styles.PressableViewStyle}>
            <View style={{flex:1}}/>
            <View style={{flex:1, justifyContent:'center', alignItems:'center',paddingTop:120}}>
              <Pressable style={styles.PressableStyle} onPress={OnPress}>
                    <Text style={{fontSize:17}}>Naprej</Text>
                </Pressable>                
            </View>
        </View>
    </View>
    );
  };
const styles = StyleSheet.create({
  container: {
      flex:1,
      justifyContent:'center',
      
  },
  textStyle: {
      fontSize: 20,
      textAlign:'center',
      alignItems:'center',
      paddingVertical:10
  },
  PressableViewStyle:{
    flex:1,
  },
  PressableStyle:{
      width:200,
      height:70,
      borderRadius:35,
      borderWidth:2,
      justifyContent:'center',
      alignItems: 'center',
      backgroundColor:'rgba(138,196,64,1)',
  },
});

export default PrologScreen;