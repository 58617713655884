import 'react-native-gesture-handler';
import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, Image } from "react-native";
import SpinningLogo from './SpinningLogo';
import values from './Values.json';
import { isEnabled } from 'react-native/Libraries/Performance/Systrace';

const PerDecisionScreen = ({navigation,route}) => {
  const [count, setCount] = useState(0);
  const isEnabled = route.params.isEnabled;
  const PersonButton = (props) => {
    return(
    <TouchableOpacity
      style={{
        flex:1,
        flexDirection:'row',
        borderColor:'rgba(0,0,0,0.8)',
        borderWidth:0.5,
        justifyContent:'flex-start',
        alignContent:'flex-start',
        backgroundColor: 'transparent',
      }}
      onPress={props.OnPress}
    >
      <View style={{flex:1, backgroundColor:'transparent', flexDirection:'column', alignItems:'center', alignContent:'center', paddingTop: 5}}>
        <Image style={{width:80,height:80,borderRadius:40, paddingTop:2}} source={props.picturePath}></Image>
        <View style={{flex:2, justifyContent:'center'}}>
          <Text style={{color: isEnabled ? 'white' : 'black'}}>{props.name}</Text>
        </View>
      </View>
      <View style={{flex:2, backgroundColor:'transaprent', flexDirection:'column', justifyContent:'space-evenly', alignItems:'center'}}>
        <Text style={{fontSize:16, paddingTop:30,color: isEnabled ? 'white' : 'black' }}>Zaključena srednja šola:</Text>
        <Text style={{fontSize:18, paddingBottom:30,textAlign:'center',color: isEnabled ? 'white' : 'black' }}>{props.srednjaSola}</Text>
      </View>
      
    </TouchableOpacity>
    );
  };
  const OnPress1 = () => {
    navigation.navigate('UniDecision', {nameNum:0,isEnabled:isEnabled});
  };
  const OnPress2 = () => {
    navigation.navigate('UniDecision', {nameNum:1,isEnabled:isEnabled});
    setCount(1);
  };
  const OnPress3 = () => {
    navigation.navigate('UniDecision', {nameNum:2,isEnabled:isEnabled});
    setCount(2);
  };
    return(
    <View style={{flex: 1,backgroundColor: isEnabled ? '#383838' : 'white'}}>
        <View style={{flex: 6.5, backgroundColor:'transparent', justifyContent:'center',borderWidth:1,paddingBottom:10}}>
            <View style={{flex:3,justifyContent:'center',alignItems:'center'}}>
            <SpinningLogo/>
            </View>
            <View style={{flex:1,alignContent:'flex-end',alignItems:'center',justifyContent:'flex-end',paddingTop:10}}>
              <Text style={{fontSize:20, color: isEnabled ? 'white' : 'black' }}>Izberi osebo:</Text>
            </View>
            
          </View> 
        <View style={{flex: 10, backgroundColor:'transparent'}}>
          <PersonButton name={'Marko'} picturePath = {require('./Picures/Marko.png')} srednjaSola={values.HighSchools[0]} OnPress={OnPress1}></PersonButton>
          <PersonButton name={'Pia'} picturePath = {require('./Picures/Pia.png')} srednjaSola={values.HighSchools[1]} OnPress={OnPress2}></PersonButton>
          <PersonButton name={'Valentin'} picturePath = {require('./Picures/Valentin.png')} srednjaSola={values.HighSchools[2]} OnPress={OnPress3}></PersonButton>
        </View>
        <View style={{flex: 4, backgroundColor:'transparent',borderWidth:1}} />
    </View>
    );
  };
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-evenly",
    flexDirection:'column',
    alignItems:'center',
    backgroundColor:'cyan',
  },
  rectangleContainer:{
    //borderWidth:1,
    width:300,
    height:200,
    borderRadius:25,
    padding:10,
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:'rgba(85,100,160,1)',
  },
  circleContainer:{
    width:100,
    height:100,
    borderRadius:50,
    backgroundColor:'black',
    borderWidth:1,
    borderColor:'white',
  },
  circleLineContainer:{
    width: 2,
    color:'cyan',
    backgroundColor:'cyan',
    flex:1,
  },
  button: {
    //borderWidth: 1.5,
    borderColor:'rgba(0,0,0,0.8)',
    alignItems:'center',
    justifyContent:'center',
    alignContent:'center',
    width:300,
    height:65,
    elevation:5,
    borderRadius:100,
    padding:5,
  },
  countContainer: {
    alignItems: "center",
    padding: 10
  },
  textContainer:{
    fontSize:15,
    textAlign:'center',
  }
});

export default PerDecisionScreen;