import 'react-native-gesture-handler';
import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, Animated, Pressable, Image } from "react-native";
import Values from './Values.json';

const SideMenuScreen = ({navigation, route}) => {
  const ScreenID = route.params.ScreenID;
  const [array,setArray] = useState(route.params.array);
  const competencesNum = [];
  const competences = [];
  const isEnabled = route.params.isEnabled;
  const socNum = route.params.socNum;
  const blackArraw = require('../assets/arrow1.png');
  const whiteArrow = require('../assets/arrow1_white.png');
  if(typeof array !== 'undefined'){
    array.forEach(array1 => {
      array1.forEach( num => {
        if(competencesNum.includes(num) == true){
          const result = competencesNum.slice().filter(i => i === num).length;
          
          if(competences.includes(Values.Competences[num] + " (" + (result) + ")") == true){
            competences.splice(competences.indexOf(Values.Competences[num] + " (" + (result) + ")"),1);
          }
          if(competences.includes(Values.Competences[num])){
          competences.splice(competences.indexOf(Values.Competences[num]),1);
          }
          competences.push(Values.Competences[num] + " (" + (result + 1) + ")");
          competencesNum.push(num);
        }
        else{
          competences.push(Values.Competences[num]);
          competencesNum.push(num);
        }
      });
    });
  }
  const OnPress = () => {
    if(ScreenID == 0){
    navigation.navigate('Decision',{array:array,socNum:socNum,isEnabled:isEnabled});
    }
    else if(ScreenID == 1){
      navigation.navigate('Decision2',{array:array,socNum:socNum,isEnabled:isEnabled});
    }else if(ScreenID < 20){
      navigation.navigate('DecisionSpecial',{array:array,socNum:socNum,isEnabled:isEnabled});
    }else{
      navigation.navigate('Home',{isEnabled:isEnabled})
    }
  }
    return(
    <View style={[styles.container,{backgroundColor: isEnabled ? '#383838' : 'white'}]}>
        <View style={{flex:1}}></View>
        <View style={{flex:6,alignContent:'center',alignItems:'center'}}>
          <Text style={{fontSize:20,color: isEnabled ? 'white' : 'black'}}>Točke socialne mreže: {socNum}</Text>
          {typeof array !== 'undefined' ?
              array.length != 0 ?
          <Text style={{fontSize:20,paddingTop:30,paddingBottom:5,color: isEnabled ? 'white' : 'black'}}>Kompetence:</Text> : <Text></Text> : <Text></Text>}
          {typeof array !== 'undefined' ?
              array.length != 0 ?
              competences.map(num => (
                <Text style={{fontSize:15,color: isEnabled ? 'white' : 'black'}}>{num}</Text>
                ))
           : <Text style={{fontSize:20,paddingTop:30,paddingBottom:5,color: isEnabled ? 'white' : 'black'}} >Tukaj boš našel kompetence!</Text> : <Text style={{fontSize:20,paddingTop:30,paddingBottom:5,color: isEnabled ? 'white' : 'black'}} >Tukaj boš našel kompetence!</Text>}
        </View>
        <View style={{flex:1, alignItems:'center', justifyContent:'center'}}>
            <Pressable onPress={OnPress}>
                <Image style={{height:50,width:50,transform: [{ rotate: '180deg' }]}} source={isEnabled ? whiteArrow : blackArraw}></Image>
            </Pressable>
        </View>
    </View>
    );
  };
const styles = StyleSheet.create({
  container: {
      flex:1,
      backgroundColor:'rgba(68,119,178,1)',
      justifyContent:'center',
  },
  textStyle: {
      fontSize: 20,
      textAlign:'center',
      alignItems:'center',
  },
  PressableViewStyle:{
    flex:1,
  },
  PressableStyle:{
      width:200,
      height:70,
      borderRadius:50,
      backgroundColor:'green',
      justifyContent:'center',
      alignItems: 'center'
  },
});

export default SideMenuScreen;