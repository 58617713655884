import * as React from 'react';
import { Button, View, Text } from 'react-native';
import HomeScreen from './Views/HomeScreen'
import DecisionScreen from './Views/DecisionScreen'
import DecisionScreen2 from './Views/DecisionScreen2'
import DecisionSpecialScreen from './Views/DecisionSpecialScreen'
import DetailsScreen from './Views/DetailsScreen'
import PrologScreen from './Views/PrologScreen'
import PerDecisionScreen from './Views/PerDecisionScreen'
import UniDecisionScreen from './Views/UniDecisionScreen'
import SideMenuScreen from './Views/SideMenuScreen'
import FinishScreen from './Views/FinishScreen'
import CreditScreen from './Views/CreditScreen'
import ActivitiesScreen from './Views/ActivitiesScreen'
import AboutScreen from './Views/AboutScreen'
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { TransitionSpecs, HeaderStyleInterpolators } from '@react-navigation/stack';


const Stack = createStackNavigator();

function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Home" headerMode={false}>
        <Stack.Screen name="Home" component={HomeScreen} />
        <Stack.Screen name="Prolog" component={PrologScreen} options={{...MyTransitionY}}/>
        <Stack.Screen name="PersonDecision" component={PerDecisionScreen} options={{...MyTransitionX_}} />
        <Stack.Screen name="UniDecision" component={UniDecisionScreen} options={{...MyTransitionX}} />
        <Stack.Screen name="Decision" component={DecisionScreen} options={{...MyTransitionY_}} />
        <Stack.Screen name="Decision2" component={DecisionScreen2} options={{...MyTransitionY_}} />
        <Stack.Screen name="DecisionSpecial" component={DecisionSpecialScreen} options={{...MyTransitionY_}} />
        <Stack.Screen name='Side Menu' component={SideMenuScreen} options={{...MyTransitionY}} />
        <Stack.Screen name="Details" component={DetailsScreen} options={{...MyTransitionX}} />
        <Stack.Screen name='Finish' component={FinishScreen} options={{...MyTransitionY_}} />
        <Stack.Screen name='Credits' component={CreditScreen} options={{...MyTransitionY_}} />
        <Stack.Screen name="Activities" component={ActivitiesScreen} options={{...MyTransitionY}}/>
        <Stack.Screen name="About" component={AboutScreen} options={{...MyTransitionY}}/>
      </Stack.Navigator>
    </NavigationContainer>
  );
}
const MyTransitionY = {
  gestureDirection: 'vertical',
  transitionSpec: {
    open: TransitionSpecs.TransitionIOSSpec,
    close: TransitionSpecs.TransitionIOSSpec,
  },
  headerStyleInterpolator: HeaderStyleInterpolators.forFade,
  cardStyleInterpolator: ({ current, next, layouts }) => {
    return {
      cardStyle: {
        transform: [
          {
            translateY: current.progress.interpolate({
              inputRange: [0, 1],
              outputRange: [layouts.screen.height, 0],
            }),
          },
          
          {
            scale: next
              ? next.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, 0.9],
                })
              : 1,
          },
        ],
      },
      overlayStyle: {
        opacity: current.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
        }),
      },
    };
  },
}
const MyTransitionY_ = {
  gestureDirection: 'vertical',
  transitionSpec: {
    open: TransitionSpecs.TransitionIOSSpec,
    close: TransitionSpecs.TransitionIOSSpec,
  },
  headerStyleInterpolator: HeaderStyleInterpolators.forFade,
  cardStyleInterpolator: ({ current, next, layouts }) => {
    return {
      cardStyle: {
        transform: [
          {
            translateY: current.progress.interpolate({
              inputRange: [0, 1],
              outputRange: [-layouts.screen.height, 0],
            }),
          },
          
          {
            scale: next
              ? next.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, 0.9],
                })
              : 1,
          },
        ],
      },
      overlayStyle: {
        opacity: current.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
        }),
      },
    };
  },
}
const MyTransitionX = {
  gestureDirection: 'horizontal',
  transitionSpec: {
    open: TransitionSpecs.TransitionIOSSpec,
    close: TransitionSpecs.TransitionIOSSpec,
  },
  headerStyleInterpolator: HeaderStyleInterpolators.forFade,
  cardStyleInterpolator: ({ current, next, layouts }) => {
    return {
      cardStyle: {
        transform: [
          {
            translateX: current.progress.interpolate({
              inputRange: [0, 1],
              outputRange: [layouts.screen.width, 0],
            }),
          },
          
          {
            scale: next
              ? next.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, 0.9],
                })
              : 1,
          },
        ],
      },
      overlayStyle: {
        opacity: current.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
        }),
      },
    };
  },
}
const MyTransitionX_ = {
  gestureDirection: 'horizontal',
  transitionSpec: {
    open: TransitionSpecs.TransitionIOSSpec,
    close: TransitionSpecs.TransitionIOSSpec,
  },
  headerStyleInterpolator: HeaderStyleInterpolators.forFade,
  cardStyleInterpolator: ({ current, next, layouts }) => {
    return {
      cardStyle: {
        transform: [
          {
            translateX: current.progress.interpolate({
              inputRange: [0, 1],
              outputRange: [-layouts.screen.width,0],
            }),
          },
          
          {
            scale: next
              ? next.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, 0.9],
                })
              : 1,
          },
        ],
      },
      overlayStyle: {
        opacity: current.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
        }),
      },
    };
  },
}

export default App;